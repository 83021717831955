<template>
  <div class="crossbar-cards transition__page" :class="{ 'isShowing': isShowing }">
    <div class="crossbar-cards__info">
      <div class="release-date">Coming Soon</div>
      <div class="platform">
        <img src="../assets/steam-logo.png" alt="Steam logo" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, shallowRef } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { delay } from '@/utils/async'

const isShowing = shallowRef<boolean>(false)

onMounted(async () => {
  document.title = 'Crossbar Cards'
  await delay(500)
  isShowing.value = true
})

onBeforeRouteLeave(async () => {
  isShowing.value = false
  await delay(1000)
})
</script>

<style lang="scss" scoped>
.crossbar-cards {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  background-color: $offBlack;
  background-image: url(../assets/crossbar-cards-bkg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;

  &__info {
    margin-bottom: 10rem;

    .release-date {
      margin-bottom: 2rem;
      font-size: 4.8rem;
      text-transform: uppercase;
    }

    .platform {
      height: 4rem;

      img {
        height: 100%;
        margin: 0;
      }
    }
  }
}
</style>